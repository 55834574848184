<template>
  <div id="app">
    <div class="wrapper">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>
<style>
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  flex: 1;
}
</style>
