<template>
  <div id="languaje" class="me-4">
    <div class="dropdown">
      <a
        class="btn btn-secondary dropdown-toggle"
        href="#"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="lang == 0"> ES</span>
        <span v-if="lang == 1"> EN</span>
      </a>

      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li>
          <a @click="setLang(0)" class="dropdown-item" href="javascript:void(0)"
            >Español</a
          >
        </li>
        <li><hr class="dropdown-divider" /></li>
        <li>
          <a @click="setLang(1)" class="dropdown-item" href="javascript:void(0)"
            >English</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "LanguajeComponent",
  data() {
    return {
      lang: 0,
    };
  },
  created: function () {
    let lenguaje = localStorage.getItem("lang");
    if (lenguaje != undefined) {
      this.lang = lenguaje;
    }
  },
  methods: {
    setLang(lang) {
      this.lang = lang;
      localStorage.setItem("lang", this.lang);
      location.reload();
    },
  },
};
</script>
